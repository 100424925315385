<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12">
                <FilterRegion
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterProvinsi
                    style="width:100%;"
                    v-model:value="state.params.provinsi"
                    v-model:region="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterArea
                    style="width:100%;"
                    v-model:value="state.params.area"
                    v-model:provinsi="state.params.provinsi"
                    v-model:region="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterKabupatenKota
                    style="width:100%;"
                    v-model:value="state.params.kabupaten"
                    v-model:area="state.params.area"
                    v-model:provinsi="state.params.provinsi"
                    v-model:region="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterDistributorCurah
                    style="width:100%;"
                    v-model:value="state.params.distributor"
                    v-model:kabupaten="state.params.kabupaten"
                    v-model:area="state.params.area"
                    v-model:provinsi="state.params.provinsi"
                    v-model:region="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <ASelect
                    :options="state.statusLists"
                    allow-clear
                    style="width:100%;"
                    placeholder="Pilih Status"
                    v-model:value="state.params.status"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AInputSearch
                    v-model:value="state.params.q"
                    placeholder="Cari..."
                    style="width:100%"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <!-- button action menu -->
        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip
                        v-if="hasRoles([ROLE_ADMIN_BK, ROLE_SPC_GROUP, ROLE_SPC, ROLE_CORSALES])"
                        title="Aktifkan">
                        <AButton
                            type="primary"
                            :loading="state.isActive"
                            @click="btnActive">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        v-if="hasRoles([ROLE_ADMIN_BK, ROLE_SPC_GROUP, ROLE_SPC, ROLE_CORSALES])"
                        title="Nonaktifkan">
                        <AButton
                            type="danger"
                            :loading="state.isInactive"
                            @click="btnInactive">
                            <i class="fa fa-ban" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        v-if="hasRoles([ROLE_ADMIN_BK, ROLE_SPC_GROUP, ROLE_SPC, ROLE_CORSALES])"
                        title="Tambahkan">
                        <AButton
                            type="primary"
                            @click="btnForm">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip title="Download excel">
                        <DownloadExcel
                            :url="state.url"
                            :params="state.params"
                            namefile="Master-Data-Distributor-Curah"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                row-key="id"
                :row-selection="{ selectedRowKeys: state.selectedRowKeys, onChange: onSelectChange }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #status="{ text }">
                    <ATag v-if="text === 'Aktif'" color="#108ee9">Aktif</ATag>
                    <ATag v-else color="grey">Tidak Aktif</ATag>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip title="Master Data Customer Curah">
                            <AButton
                                class="button"
                                size="small"
                                @click="() => {
                                    router.push({
                                        path: '/atbusiness/customer-curah',
                                        query: { distributor: record.id },
                                    })
                                }">
                                <i class="fe fe-list" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK, ROLE_SPC_GROUP, ROLE_SPC, ROLE_CORSALES])"
                            title="Edit">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record)">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                        <ATooltip title="Lihat">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record, true)">
                                <i class="fe fe-eye" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- form -->
        <Form
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            @success="fetchDataList"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterRegion from '@/components/filter/FilterRegional'
import FilterProvinsi from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterDistributorCurah from '@/components/filter/FilterDistributorCurah'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import Form from './Form'
import { pickBy } from 'lodash'
import moment from 'moment'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_KAM,
    ROLE_SPC,
    ROLE_SPC_GROUP,
    ROLE_CORSALES,
} from '@/helpers'

export default defineComponent({
    components: {
        FilterRegion,
        FilterProvinsi,
        FilterArea,
        FilterKabupatenKota,
        FilterDistributorCurah,
        DownloadExcel,
        Form,
    },
    setup() {
        const errorMessage = ref()
        const router = useRouter()
        const state = reactive({
            columns: [
                {
                    title: 'No',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Kode Distributor Curah',
                    dataIndex: 'code',
                },
                {
                    title: 'Nama Distributor Curah',
                    dataIndex: 'name',
                },
                {
                    title: 'Alamat Distributor Curah',
                    dataIndex: 'address',
                },
                {
                    title: 'Cakupan Area Customer Curah',
                    dataIndex: 'areas',
                },
                {
                    title: 'Jumlah Customer Curah',
                    dataIndex: 'jumlah_toko',
                },
                {
                    title: 'Status Distributor Curah',
                    dataIndex: 'status_text',
                    slots: { customRender: 'status' },
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            url: '/api/distributors-curah',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isActive: false,
            isInactive: false,
            params: {
                q: '',
                distributor: [],
                kabupaten: [],
                area: [],
                provinsi: [],
                region: [],
                status: null,
                page: 1,
                "per-page": 10,
            },
            selectedRows: [],
            selectedRowKeys: [],
            statusLists: [
                { value: 1, label: 'Aktif' },
                { value: 0, label: 'Tidak Aktif' },
            ],
        })

        const onSelectChange = (keys, rows) => {
            state.selectedRowKeys = keys
            state.selectedRows = rows
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.url, {
                    params: {
                        ...pickBy(state.params),
                        status: state.params.status,
                    },
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnActive = () => {
            if (state.selectedRowKeys.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            const promises = []
            state.isActive = true
            state.selectedRowKeys.forEach(k => {
                let find = state.data.find(item => item.id == k)

                promises.push(
                    apiClient.post(`${state.url}/${k}?_method=PUT`, {
                        active: true,
                        type: find.type,
                    }),
                )
            })

            Promise.all(promises)
                .then(() => {
                    message.success('Distributor curah berhasil diaktifkan')
                })
                .finally(err => {
                    state.selectedRowKeys = []
                    fetchDataList()
                    state.isActive = false
                })
        }

        const btnInactive = () => {
            if (state.selectedRowKeys.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            const promises = []
            state.isInactive = true
            state.selectedRowKeys.forEach(k => {
                let find = state.data.find(item => item.id == k)

                promises.push(
                    apiClient.post(`${state.url}/${k}?_method=PUT`, {
                        active: false,
                        type: find.type,
                    }),
                )
            })

            Promise.all(promises)
                .then(() => {
                    message.success('Distributor curah berhasil dinonaktifkan')
                })
                .finally(() => {
                    state.selectedRowKeys = []
                    fetchDataList()
                    state.isInactive = false
                })
        }

        // handle form
        const visibleFormModal = ref(false);
        const visibleFormItemModal = ref(null);

        const btnForm = (item = null, readOnly = false) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            router,
            visibleFormModal,
            visibleFormItemModal,
            btnForm, // create or update
            btnActive,
            btnInactive,
            fetchDataList,
            state,
            onSelectChange,
            handleTableChange,
            errorMessage,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_KAM,
            ROLE_SPC,
            ROLE_SPC_GROUP,
            ROLE_CORSALES,
        }
    },
})
</script>
